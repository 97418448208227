<template>
  <div class="converter">
    <p class="text-h4">Numeral system conversions</p>
    <v-data-table
      :headers="headers"
      :items="powers_of_ten"
      :items-per-page="15"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item.int_value="{ item }">
       10<sup>{{item.int_value}}</sup>
      </template>
    </v-data-table>
  </div>
</template>


<script>
export default {
    name: 'Converter',
    components: {},
    data() {
      return {
        headers:[
          {text: 'Powers of ten', align: 'start', value:'name', sortable:false},
          {text: 'Powers of ten', align: 'start', value:'int_value', sortable:false},
          {text: 'Indian convention', align: 'start', value:'ind_name', sortable:false},
          {text: 'Western convetion', align: 'start', value:'us_name', sortable:false},
        ],
        powers_of_ten:[
          {name:'100',int_value:2, us_name:'Hundred', ind_name:'Hundred'},
          {name:'1,000', int_value:3, us_name:'Thousand', ind_name:'Thousand'},
          {name:'10,000', int_value:4, us_name:'Ten thousand', ind_name:'Ten thousand'},
          {name:'100,000', int_value:5, us_name:'Hundred thousand', ind_name:'One lakh'},
          {name:'1,000,000', int_value:6, us_name:'One million', ind_name:'Ten lakhs'},
          {name:'10,000,000', int_value:7, us_name:'Ten million', ind_name:'One crore'},
          {name:'100,000,000', int_value:8, us_name:'Hundred million', ind_name:'Ten crore'},
          {name:'1,000,000,000', int_value:9, us_name:'One billion', ind_name:'Hundred crore'},
          {name:'10,000,000,000', int_value:10, us_name:'Ten billion', ind_name:'One thousand crore'},
          {name:'100,000,000,000', int_value:11, us_name:'Hundred billion', ind_name:'Ten thousand crore'},
          {name:'1,000,000,000,000', int_value:12, us_name:'One trillion', ind_name:'One lakh crore'},
          {name:'10,000,000,000,000', int_value:13, us_name:'Ten trillion', ind_name:'Ten lakh crore'},
          {name:'100,000,000,000,000', int_value:14, us_name:'Hundred trillion', ind_name:'One crore crore'},
        ],
      }
    },
    
  }
</script>
